<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="7" class="d-none d-sm-flex align-items-center p-5">
        <b-row class="d-flex justify-content-center">
          <b-col
            lg="9"
            class="align-items-center d-flex justify-content-center"
          >
            <b-img
              fluid-grow
              :src="require('@/assets/images/logo/MMDefaultLogo.jpg')"
              alt="Login V2"
            />
          </b-col>
          <b-col
            lg="9"
            class="align-items-center d-flex justify-content-center"
          >
            <b-img fluid-grow :src="imgUrl" alt="Login V2" />
          </b-col>
        </b-row>
      </b-col>
      <b-col lg="4" class="d-flex align-items-center auth-bg px-1">
        <b-col sm="8" md="6" lg="10" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-2">
            <b-col sm="8" md="6" lg="8" class="my-4 mx-auto">
              <b-row class="d-flex align-items-center justify-content-center">
                <b-avatar
                  size="12rem"
                  :src="require('@/assets/images/logo/M.jpg')"
                  alt="login V2"
                />
              </b-row>
            </b-col>
            <b-card-text class="mb-2">
              <span
                class="text-light align-items-center d-flex justify-content-center h1"
                >Admin Account</span
              ></b-card-text
            >
            <b-card-text class="mb-3">
              <span
                class="align-items-center d-flex justify-content-center h2"
                style="color: #FF5100"
                >Sign in
              </span></b-card-text
            >

            <span class="text-light" style="font-size: xx-large">
              Welcome to MobileMasr!</span
            >
          </b-card-title>
          <b-card-text class="mb-2">
            <span style="font-size: medium; color: #FF5100"
              >Please sign-in to your account and start Control
            </span></b-card-text
          >

          <!-- form -->
          <validation-observer ref="loginValidation" >
            <b-form class="mt-2" @submit.prevent>
              <!-- email -->
              <b-form-group label-for="login-email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="login-email"
                    v-model="LoginData.email"
                    :state="errors.length > 0 ? false : null"
                    name="login-email"
                    placeholder="Ahmed@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!--Validate email error-->
                  <small v-if="invalideEmail" class="text-danger">{{
                    invalideEmailText
                  }}</small>
                  <!--Authorized email error-->
                </validation-provider>
              </b-form-group>
              <!-- forgot password -->
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="spassword"
                      autocomplete="on"
                      v-model="LoginData.password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <!-- Required password error-->
                  <small v-if="invalidePassword" class="text-danger">{{
                    invalsidePasswordText
                  }}</small>
                  <!-- Password length error-->
                </validation-provider>
              </b-form-group>
              <!-- checkbox -->
              <b-form-group>
                <b-form-checkbox
                  id="remember-me"
                  v-model="status"
                  name="checkbox-1"
                >
                  <span style="color: #FF5100">Remember Me</span>
                </b-form-checkbox>
              </b-form-group>

              <validation-provider
                  v-if="otpValisiable"
                  #default="{ errors }"
                  name="otp"
                  rules="required"
                >
                <b-input-group
                    class="input-group-merge my-2"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                  <b-form-input
                          id="otp"
                          v-model="LoginData.otp"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          type="number"
                          name="password"
                          placeholder="Enter your Otp"
                        />
                  </b-input-group>
                        <b-input-group
                          class="input-group-merge my-2"
                          :class="errors.length > 0 ? 'is-invalid' : null"
                        >
                    <a href="#" @click.prevent="sendOtp" v-if="resendDisabled == false"  class="otp-send"  target="_blank" rel="noopener noreferrer">Resend Otp </a>
                    <a href="#"@click.prevent=""  v-else  class="otp-disabled"  target="_blank" rel="noopener noreferrer">Resend Otp </a>
                    <span v-if="resendDisabled" > ({{ countdown }})</span>


                  </b-input-group>

              </validation-provider>
               

              
              <!-- submit buttons -->
              <b-button
             
                size="lg"
                type="submit"
                class="float-right"
                style="background-color: #FF5100 !important"
                @click="SubmitLogin"
              >
                Sign in
              </b-button>


            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import VuexyLogo from '@core/layouts/components/Logo.vue'


import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { number } from 'echarts/lib/export'


export default {
  components: {
    VuexyLogo,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      languageSelect: {
        text: '',
        value: '',
      },
      invalidePasswordText:
        'Your password must be equel or more than 6 Letters',
      invalidePassword: false,
      invalideEmailText: 'Your email or password is incorrect',
      invalideEmail: false,
      status: '',
      LoginData: {
        password: '',
        email: '',
        otp: ''
      },
      sideImg: require('@/assets/images/logo/login-sec.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isactive: false,
      otpValisiable: false,
      resendDisabled: false,
      countdown: 30
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {

    sendOtp(){

      
                axios
                .post('/resend-otp', this.LoginData)
                .then((result) => {

                   // Disable the button for 30 seconds
                    this.resendDisabled = true;
                    let timer = setInterval(() => {
                      if (this.countdown > 0) {
                        this.countdown--;
                      } else {
                        clearInterval(timer);
                        this.resendDisabled = false;
                        this.countdown = 30;
                      }
                    }, 1000);

                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'OTP sent',
                    text: "OTP Re-sent successfully, Check your E-mail",
                    showConfirmButton: false,
                    timer: 4000,
                  });
                  this.otpValisiable = true;
                  
                 
                })
                .catch((err) => {
                   

                    this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: err.response.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  if (error.response.status === 401) {
                    this.invalideEmail = true
                    this.isactive = false
                  }

                })
    },

    // Start Login
    SubmitLogin() {
      this.isactive = true
      if (this.LoginData.password.length < 6) {
        //check validate passowrd
        this.isactive = false
        this.invalidePassword = true
      } else {
        this.invalideEmail = false
        this.invalidePassword = false
        // check login validation
        return new Promise((resolve, reject) => {
          this.$refs.loginValidation.validate().then((success) => {
            if (success) {
              // Validate Form Alert

              //start request from api
              this.$store
                .dispatch('login', this.LoginData)
                .then((result) => {
                  if(result.data.message == "OTP sent successfully"){

                    this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'OTP sent',
                    text: "OTP sent successfully, Check your E-mail",
                    showConfirmButton: false,
                    timer: 4000,
                  });
                    this.otpValisiable = true;
                  }
                  else{
                  const data = result.data.data
                  if (data.locale == 'en') {
                    this.languageSelect.text = 'English'
                    this.languageSelect.value = 'en'
                    this.$session.set('lang', this.languageSelect)
                  } else {
                    this.languageSelect.text = 'Arabic'
                    this.languageSelect.value = 'ar'
                    this.$session.set('lang', this.languageSelect)
                  }
                  this.$swal({
                    position: 'center',
                    icon: 'success',
                    title: 'You are logged in',
                    showConfirmButton: false,
                    timer: 1000,
                  })
                  // this.$router.push();
                  this.isactive = false
                }
                })
                .catch((error) => {
                  this.$swal({
                    position: 'center',
                    icon: 'error',
                    title: error.response ?  error.response.data.message : 'Error',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  if (error.response.status === 401) {
                    this.invalideEmail = true
                    this.isactive = false
                  }
                })
            }
          })
        })
      }
    },
  },
}
</script>



<style>

.otp-send{
  color:rgb(255, 81, 0) !important;
  text-decoration: underline;
}

.otp-disabled{
  color:slategray !important; 
  text-decoration: underline;
  margin-inline: 2px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
